export enum Challenges {
  enableServerlessChallenge = 'specs.challenges.OOIEnableServerlessAPIChallenge',
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
  enableAutopilot = 'specs.challenges.BMEnableAutopilot',
  enableAutopilotADI = 'specs.challenges.BMEnableAutopilotADI',
  enableAutopilotEditorX = 'specs.challenges.BMEnableAutopilotEditorX',
  enableDripContent = 'specs.challenges.WebMemberDripContent',
  enableMyProgramsMA = 'specs.challenges.OOIMyProgramsMA',
  enableGroupVisibilitySP = 'specs.challenges.OOIGroupsVisibilitySP',
  enableProgramListTitle = 'specs.challenges.OOIEnableProgramListTitle',
  enableCategoriesTabs = 'specs.wos.ProgramsCategory',
  enableSPEmptyState = 'specs.challenges.OOIOwnerSettingsPanelEmptyState',
  enabledExtendedMobileSettings = 'specs.viewer-apps-2936472a-a1ed-4ae5-9f71-614313a9f4e7.OOIEnableExtendedMobileSettings',
  enableCompleteStepButtonText = 'specs.viewer-apps-2936472a-a1ed-4ae5-9f71-614313a9f4e7.OOIEnableCompleteButtonText',
}
